import { useContext } from 'react';
import { ClientLocales } from '../../../../common/utils/hydration';
import { I18nContext } from './context';
import { I18nHook } from './types';

export function useI18n(): I18nHook {
    const i18n = useContext<ClientLocales>(I18nContext);

    return {
        getCommitmentMessageText() {
            return i18n?.commitmentMessage || '';
        },
        getSellerCombinedItemsMessageText() {
            return i18n?.sellerCombinedItemsMessage || '';
        },
        getCartPageTitle() {
            return i18n?.cartPageTitle || '';
        },
        getCombinedShippingRequestedMessage() {
            return i18n?.combinedShippingRequestedMessage || '';
        },
        getPaymentIsProcessingMessage() {
            return i18n?.paymentIsProcessingMessage || '';
        },
        getPaymentProcessesTemplateMinutesMessage() {
            return i18n?.paymentProcessesTemplateMinutesMessage || '';
        },
        getPaymentProcessesTemplateSecondsMessage() {
            return i18n?.paymentProcessesTemplateSecondsMessage || '';
        },
        getPaymentProcessesTemplateHourTodayMessage() {
            return i18n?.paymentProcessesTemplateHourTodayMessage || '';
        },
        getPaymentProcessesTemplateHourAndDate() {
            return i18n?.paymentProcessesTemplateHourAndDate || '';
        },
        getCheckoutTemplateSecondsMessage() {
            return i18n?.checkoutTemplateSecondsMessage || '';
        },
        getCheckoutTemplateMinutesMessage() {
            return i18n?.checkoutTemplateMinutesMessage || '';
        },
        getCheckoutTemplateHourTodayMessage() {
            return i18n?.checkoutTemplateHourTodayMessage || '';
        },
        getCheckoutTemplateHourAndDateMessage() {
            return i18n?.checkoutTemplateHourAndDateMessage || '';
        },
        getGenericErrorMessage() {
            return i18n?.genericErrorMessage || '';
        },
        getSignInChooserCloseDialog() {
            return i18n?.signInChooserCloseDialog || '';
        },
        getSignInChooserContinueAsGuest() {
            return i18n?.signInChooserContinueAsGuest || '';
        },
        getSignInChooserDialogAriaLabel() {
            return i18n?.signInChooserDialogAriaLabel || '';
        },
        getSignInChooserContinueToSignIn() {
            return i18n?.signInChooserContinueToSignIn || '';
        },
        getSpinnerAriaLabel() {
            return i18n?.spinnerAriaLabel || 'Loading';
        },
        getBulkActionsTourtipContent() {
            return i18n?.bulkActionsTourtipContent || '';
        },
        getBulkActionsTourtipA11YClose() {
            return i18n?.bulkActionsTourtipA11YClose || '';
        },
        getBulkCheckoutTourTipContent() {
            return i18n?.bulkCheckoutTourTipContent || '';
        },
        getSignalsLocales() {
            return i18n?.signalsLocales || {};
        },
        getEmptyCartWithWatchlist() {
            return i18n?.emptyCartWithWatchlist || {};
        },
        getSFLModuleTitle() {
            return i18n?.sflModuleTitle || '';
        },
        getEmptyCartMessage() {
            return i18n?.emptyCartMessage || '';
        },
    };
}
