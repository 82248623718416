import { EbayPageNoticeTitle } from '@ebay/ui-core-react/ebay-page-notice';
import { Action } from '@ebay/experience-types-ebay';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { useI18n } from '../../../../utils/i18n';
import { TextDisplay } from '../../../text-display';
import { AlertLevel } from '../../types';
import { useAppMetaContextData } from '../../../../utils/app-meta-context/hooks';
import { TEST_IDS } from '../../../../utils/test-ids';
import { triggerSignInOTPAuthFlow } from '../../../../utils/auth-modal';
import { useFeatureFlagsData } from '../../../../utils/experiments/hooks';
import { Countdown as CountdownType } from '../../../../../../common/utils/hydration';
import { AlertContentProps } from './types';

function remainingSeconds(seconds: string) {
    const secInt = Number(seconds);
    if (secInt) {
        return `${secInt}`;
    }
    return '';
}

function remainingMinutes(minutes: string) {
    const minInt = Number(minutes);
    if (minInt) {
        return `${minInt}`;
    }
    return '';
}

function buildCountDownRenderer(countdown: CountdownType, locale?: string) {
    function CountDownRenderer(props: CountdownRenderProps) {
        const date = new Date(props?.props?.date);
        if (!date || isNaN(date?.getTime()) || !countdown) {
            return null;
        }

        // Less than 1 minute left.
        const oneMinMs = 60 * 1000;
        if (date < new Date(new Date(Date.now()).getTime() + oneMinMs)) {
            const secondsLeftStr = remainingSeconds(props?.formatted.seconds);
            const text = countdown.templateSeconds.replace('<seconds>', secondsLeftStr);
            return (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            );
        }

        // Less than 1 hour left.
        const oneHourMs = 60 * 60 * 1000;
        if (date <= new Date(new Date(Date.now()).getTime() + oneHourMs)) {
            const minutesLeftStr = remainingMinutes(props?.formatted.minutes);
            const text = countdown.templateMinutes.replace('<minutes>', minutesLeftStr);
            return (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            );
        }

        // Today.
        if (date.toDateString() == new Date(Date.now()).toDateString()) {
            const hourStr = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
                hour12: true,
            }).format(date);
            const text = countdown.templateHourToday.replace('<hour>', hourStr);
            return (
                <span dangerouslySetInnerHTML={{ __html: text }} />
            );
        }

        // Full time date.
        let timeDateStr;
        // For en- languages formatting is different: hour should go ahead of month.
        if (locale?.startsWith('en')) {
            const hourStr = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
                hour12: true,
            }).format(date);
            const monthStr = new Intl.DateTimeFormat(locale, {
                month: 'short',
                day: 'numeric',
            }).format(date);
            // Removing spaces and capitalization for hour string to align with eBay guidance for en- locales.
            timeDateStr = `${hourStr.toLowerCase().replace(/\s+/g, '')} on ${monthStr}`;
        } else {
            timeDateStr = new Intl.DateTimeFormat(locale, {
                hour: 'numeric',
                month: 'long',
                day: 'numeric',
                hour12: true,
            }).format(date);
        }
        const text = countdown.templateHourAndDate.replace('<houranddate>', timeDateStr);
        return (
            <span dangerouslySetInnerHTML={{ __html: text }} />
        );
    }
    return CountDownRenderer;
}

export function AlertContent({ errorState, level, notification }: AlertContentProps) {
    const i18n = useI18n();
    const { appMeta, meta } = useAppMetaContextData();
    const featureFlagsData = useFeatureFlagsData();

    if (!errorState && !notification?.message?.length && !notification?.title && !notification?.countdown) {
        return null;
    }

    if (errorState) {
        const ErrorMessageWrapper = level === AlertLevel.Page ? EbayPageNoticeTitle : 'p';
        return (
            <ErrorMessageWrapper className="error-state">
                {i18n.getGenericErrorMessage()}
            </ErrorMessageWrapper>
        );
    }

    let title = notification?.title;
    let messages = notification?.message;
    if (!title && level === AlertLevel.Page) {
        title = notification?.message?.[0];
        messages = notification?.message?.slice(1);
    }

    const handleClick = (action?: Action) => {
        if (action?.name === 'SIGN_IN') {
            triggerSignInOTPAuthFlow(true, appMeta,
                meta?.signInPageUrlObj?.signInPageUrl || action?.URL,
                featureFlagsData);
        } else if (action?.URL) {
            window.location.href = action.URL;
        }
    };

    const countdown = notification?.countdown;
    const isCountdownActive = countdown && (new Date() < countdown.date); // To avoid looped reloads on expiration.
    return (
        <>
            {title && (
                <EbayPageNoticeTitle>
                    <TextDisplay
                        data={title}
                        doAction={handleClick}
                        testId={TEST_IDS.signIn}
                    />
                </EbayPageNoticeTitle>
            )}

            {messages?.map((message, index) => (
                <p key={index}>
                    <TextDisplay data={message} />
                </p>
            ))}

            {countdown &&
                (
                    <p>
                        <Countdown
                            date={countdown.date}
                            renderer={buildCountDownRenderer(countdown, meta?.locale)}
                            onComplete={() => {
                                if (isCountdownActive) {
                                    window.location.reload();
                                }
                            }}
                        />
                    </p>
                )
            }
        </>
    );
}
