import { TextualDisplay } from '@ebay/experience-types-ebay';
import { Attributes, Attribute, Notification, MessageType, Countdown, EmptyCart } from '../../../../../common/utils/hydration';
import { I18nHook } from '../../../utils/i18n/types';

function getAttribute(attributes: Attribute[] | undefined, attributeName: string): string | undefined {
    return attributes?.find((attr) => attr.name === attributeName)?.value;
}

export function isAttributeValueTrue(attributes: Attribute[] | undefined, attributeName: string): boolean {
    if (!attributes?.length) {
        return false;
    }
    const attribute = attributes.find((attr) => attr.name === attributeName);
    if (!attribute) {
        return false;
    }
    return attribute.value === 'true';
}

function buildTextualDisplay(text: string): TextualDisplay {
    return {
        textSpans: [{
            text: text,
        }]
    };
}

function buildNotification(type: MessageType, title?: string, message?: string): Notification {
    let accessibilitySeverity;
    switch (type) {
        case MessageType.Success:
            accessibilitySeverity = 'Success';
            break;
        case MessageType.Info:
            accessibilitySeverity = 'Information';
            break;
    }
    const notification: Notification = {
        accessibilitySeverity: accessibilitySeverity,
        messageType: type,
        displayIcon: true,
    };
    if (title) {
        notification.title = buildTextualDisplay(title);
    }
    if (message) {
        notification.message = [buildTextualDisplay(message)];
    }
    return notification;
}

export function buildNotificationsFromAttributes(attributes: Attributes | undefined, i18n: I18nHook): Notification[] {
    if (!attributes) {
        return [];
    }

    const processingScheduledDateStr = getAttribute(attributes?.infoAttributes, 'processingScheduledDate');
    const isCountdownActive = processingScheduledDateStr && (
        new Date(processingScheduledDateStr) > new Date(Date.now()));

    // The seller combined these items case.
    const sellerSentInvoice = isAttributeValueTrue(attributes?.infoAttributes, 'sellerSentInvoice');
    if (sellerSentInvoice) {
        // No countdown date supplied.
        if (!processingScheduledDateStr) {
            return [buildNotification(MessageType.Success, i18n.getSellerCombinedItemsMessageText())];
        }
        // Active countdown.
        if (isCountdownActive) {
            const notification = buildNotification(MessageType.Success, i18n.getSellerCombinedItemsMessageText());
            notification.countdown = {
                date: new Date(processingScheduledDateStr),
                templateSeconds: i18n.getCheckoutTemplateSecondsMessage(),
                templateMinutes: i18n.getCheckoutTemplateMinutesMessage(),
                templateHourToday: i18n.getCheckoutTemplateHourTodayMessage(),
                templateHourAndDate: i18n.getCheckoutTemplateHourAndDateMessage(),
            };
            return [notification];
        }
        // Expired countdown.
        return [buildNotification(
            MessageType.Success, i18n.getSellerCombinedItemsMessageText(), i18n.getPaymentIsProcessingMessage())];
    }

    // Combined shipping requested case.
    const combinedShippingRequested = isAttributeValueTrue(attributes?.infoAttributes, 'combineShippingRequested');
    const countdown: Countdown | undefined = processingScheduledDateStr ?
        {
            date: new Date(processingScheduledDateStr),
            templateSeconds: i18n.getPaymentProcessesTemplateSecondsMessage(),
            templateMinutes: i18n.getPaymentProcessesTemplateMinutesMessage(),
            templateHourToday: i18n.getPaymentProcessesTemplateHourAndDate(),
            templateHourAndDate: i18n.getPaymentProcessesTemplateHourAndDate(),
        } : undefined;
    if (combinedShippingRequested) {
        // No countdown date supplied.
        if (!processingScheduledDateStr) {
            return [buildNotification(
                MessageType.Info,
                i18n.getCombinedShippingRequestedMessage(),
                i18n.getCommitmentMessageText()
            )];
        }
        // Active countdown.
        if (isCountdownActive) {
            const notification = buildNotification(MessageType.Info, i18n.getCombinedShippingRequestedMessage());
            notification.countdown = countdown;
            return [notification];
        }
        // Expired countdown.
        return [buildNotification(
            MessageType.Info, i18n.getCombinedShippingRequestedMessage(), i18n.getPaymentIsProcessingMessage())];
    }

    // A countdown case, but not a specific one.
    if (countdown) {
        if (isCountdownActive) {
            const notification = buildNotification(MessageType.Info);
            notification.countdown = countdown;
            return [notification];
        }
        return [buildNotification(MessageType.Info, i18n.getPaymentIsProcessingMessage())];
    }

    // No valid notification cases or countdowns found.
    return [];
}

export function buildEmptyCartModule(i18n: I18nHook): EmptyCart {
    return {
        emptyCartMessage: buildTextualDisplay(i18n.getEmptyCartMessage())
    };
}